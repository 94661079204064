<template>
  <!--    编辑-->
  <el-dialog :title="formTitle" :visible.sync="messageBox" width="35vw" class="addAlameBox"
    :close-on-click-modal="false">

    <!-- "cpId": 0, //产品ID
    /**
     * 冷库ID
     * 
     * 前端列表展示，使用[el-cascader]来渲染
     */
    "wsId": 0,
    "capacity": 0.0, //入库数量
    "rkrq": "@datetime(\"yyyy-MM-dd\")", //入库日期,格式：yyyy-MM-dd
    "rkgly": "", //入库管理员
    "gnId": 0, //果农ID
    "htqx": 0, //合同期限（天）
    "dqrq": "@datetime(\"yyyy-MM-dd\")", //到期日期,格式：yyyy-MM-dd
    "price": 0.0, //价格
    "fj": "", //附件
    "creator": "", //创建人
    /**
     * 果农电话
     * 
     * 新增/修改/详情，使用
     */
    "gndh": "",
    /**
     * 果农名称
     * 
     * 新增/修改/详情，使用
     */
    "gnmc": "",
    /**
     * 果农地址
     * 
     * 新增/修改/详情，使用
     */
    "gndz": "",
    /**
     * 产品名称
     * 
     * 详情使用
     */
    "cpmc": "",
    /**
     * 产品规格
     * 
     * 详情使用
     */
    "spec": "", -->

    <el-form ref="form" :model="addForm" label-width="120px">
<!--      <el-form-item label="合同编号">
        <el-input disabled v-model="addForm.id"></el-input>
      </el-form-item>-->
      <el-form-item label="产品">
        <el-select disabled v-model="addForm.cpId" placeholder="请选择产品" @change="productChange">
          <el-option v-for="item in productData" :key="item.id" :label="item.cpmc + '(' + item.spec + ')'"
            :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="冷库">
        <el-cascader disabled v-model="addForm.wsId" :options="treeData" :show-all-levels="false" :props="{
          expandTrigger: 'hover',
          emitPath: false,
          value: 'id',
          label: 'name',
        }"></el-cascader>
      </el-form-item>
      <div style="border: 1px solid red">
        <el-form-item required label="出库数量">
          <el-input-number v-model="addForm.capacity"></el-input-number>
        </el-form-item>
        <el-form-item required label="出库日期">
          <el-date-picker v-model="addForm.ckrq" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                          placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <!--      <el-form-item label="出库价格">
                <el-input-number  v-model="addForm.price" label="描述文字"></el-input-number>
              </el-form-item>-->
        <el-form-item required label="出库管理员">
          <el-input v-model="addForm.ckgly"></el-input>
        </el-form-item>
      </div>

      <el-form-item label="入库日期">
        <el-date-picker disabled v-model="addForm.rkrq" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                        placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="入库管理员">
        <el-input disabled v-model="addForm.rkgly"></el-input>
      </el-form-item>
      <el-form-item label="果农">
        <el-select disabled v-model="addForm.gnId" placeholder="请选择果农" allow-create filterable default-first-option
          @change="personChange">
          <el-option :label="item.gnmc" :value="item.id" v-for="item in personData" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="果农电话">
        <el-input disabled v-model="addForm.gndh"></el-input>
      </el-form-item>
      <el-form-item label="果农地址">
        <el-input disabled v-model="addForm.gndz"></el-input>
      </el-form-item>
<!--      <el-form-item label="合同期限（天）">
        <el-input-number disabled v-model="addForm.htqx" label="描述文字"></el-input-number>
      </el-form-item>-->
      <el-form-item label="到期日期">
        <el-date-picker disabled v-model="addForm.dqrq" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date"
          placeholder="选择日期">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="附件">
        <FileUpload ref="myFile" :fileRealTimeUp="false" :fileListModify="fileListModify" upFileKey="fj"
          valueType="string" :fileType='["jpg", "jpeg", "png"]' :limit="5" @getFileListAndForm="getFileListAndForm" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{ formTitle == "出库" ? '立即出库' : '' }}</el-button>
        <el-button @click="handleCancel">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import FileUpload from "@/components/lxw/FileUpload/index";
export default {
  components: { FileUpload },
  props: {
    formTitle: {
      type: String,
      default: "出库",
    },
    messageBox: {
      type: Boolean,
      default: false,
    },
    showFlag: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      addForm: {},
      rules: {
        // baseName: [
        //   {required: true, message: "基地名称不可为空", trigger: ["blur", "change"]},
        // ],
      },
      fileListModify: [],

      treeData: [], // 库
      personData: [], // 果农
      productData: [], // 产品
    };
  },
  watch: {
    messageBox: {
      handler(newVal, oldVal) {
        this.$get(`/pro09/lengku/tree`).then((res) => {
          if (res.data.state == "success") {
            this.treeData = res.data.data || [];
          }
        });
        this.$get(`/pro09/gnxx/page`, {
          page: 1,
          size: 10000,
        }).then((res) => {
          if (res.data.state == "success") {
            this.personData = res.data.data || [];
          }
        });
        this.$get(`/pro09/chanpin/list`).then((res) => {
          if (res.data.state == "success") {
            this.productData = res.data.data || [];
          }
        });
      },
    },
    record: {
      handler(newVal, oldVal) {
        this.addForm = {
          ...newVal,
          cpId: newVal.cpId,
          wsId: newVal.wsId,
          gnId: newVal.gnId,
          price:0
        };
        let fj = newVal.fj ? JSON.parse(newVal.fj) : [];
        if (fj.length > 0) {
            let url = "http://public.half-half.cn/"+fj[0];
            this.fileListModify = [
              { name: fj[0], url: url },
            ];
        } else {
          this.fileListModify = [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    // console.log(this.record, "record");
  },
  methods: {
    getFileListAndForm(obj) {
      if (obj && obj.fileList) {
        const { fileList } = obj;
        let list = [];
        fileList.forEach((item) => {
          if (
            item.response &&
            item.response.data
          ) {
            list.push(item.response.data);
          }
        });
        this.filesList = list;
      }
    },
    onSubmit() {
      // console.log("submit!");
      // console.log(this.addForm);
      // console.log(this.filesList, "============");

      let params = { ...this.addForm };

      if (this.addForm.gnId != Number(this.addForm.gnId)) {
        // 新增的
        params.gnmc = this.addForm.gnId;
        params.gnId = null;
      }

      params.fj = (this.filesList && this.filesList.length > 0) ? JSON.stringify(this.filesList) : null;
          params.inwsId=this.addForm.id;
          params.id=null;

        // console.log(params, "##########");
        this.saveForm(params);
    },
    // 确认提交
    saveForm(param) {
      let url = "/pro09/inout/saveOutws";

      this.$postJSON(url, param).then((res) => {
        if (res.data.state == "success") {
          if (this.formTitle == "出库") {
            this.$message.success("出库成功");
          } else {
            this.$message.success("出库成功");
          }
          this.$emit("success");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    productChange(e) {
      let r = this.productData.filter((item) => item.id == e);
      if (r.length > 0) {
        this.$set(this.addForm, "cpmc", r[0].cpmc);
        this.$set(this.addForm, "spec", r[0].spec);
        return;
      }
      this.$set(this.addForm, "cpmc", null);
      this.$set(this.addForm, "spec", null);
    },

    personChange(e) {
      if (e == Number(e)) {
        let r = this.personData.filter((item) => item.id == e);
        if (r.length > 0) {
          this.$set(this.addForm, "gndh", r[0].gndh);
          this.$set(this.addForm, "gndz", r[0].gndz);
          this.$set(this.addForm, "gnmc", r[0].gnmc);
          return;
        }
      }

      this.$set(this.addForm, "gndh", "");
      this.$set(this.addForm, "gndz", ""); // this.addForm.gnmc = r[0].gnmc;
      this.$set(this.addForm, "gnmc", null);
    },

    // 重置字段
    resetForm() {
      this.$refs["form"].resetFields();
    },

    handleCancel() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.el-select {
  width: 100%;
}

.el-cascader {
  width: 100%;
}

.el-input-number {
  width: 100%;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

.addAlameBox ::v-deep .el-dialog .el-dialog__body {
  padding-right: 50px !important;
  height: 64vh;
  overflow: scroll;
}
</style>